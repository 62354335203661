@mixin scrollbars(
  $size: 10px,
  $foreground-color: #999,
  $background-color: #0e1818
) {
  // For Chrome & Safari
  &::-webkit-scrollbar {
    width: $size;
    height: $size;
  }
  &::-webkit-scrollbar-thumb {
    background: $foreground-color;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-track {
    background: $background-color;
  }

  // Standard version (Firefox only for now)
  scrollbar-color: $foreground-color $background-color;
}
