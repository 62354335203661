
.share-btn {
  background: transparent;
  border: 1px solid rgba(183,140,37,0.8);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: .75rem;
  color: #f5f5f5;
  position: relative;
  margin: .5em;
  cursor: pointer;
  text-decoration: none;
  transition: all .15s ease;
  fa-icon {
    margin-right: 10px;
  }
  &:hover {
    background-color: #FFF1;
  }
  &:active {
    background-color: rgba(183,140,37,0.3);
  }
}

.clear-btn {
  background: transparent;
  border: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: .75rem;
  color: #f5f5f5;
  position: relative;
  margin: .5em;
  cursor: pointer;
  text-decoration: none;
  transition: all .15s ease;
  fa-icon {
    margin-right: 10px;
  }
  &:hover {
    background-color: #FFF1;
  }
  &:active {
    background-color: rgba(183,140,37,0.3);
  }
}

.dim-btn {
  background: transparent;
  border: 1px solid var(--dim-orange);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: .75rem;
  color: #f5f5f5;
  position: relative;
  margin: .5em;
  cursor: pointer;
  text-decoration: none;
  transition: all .15s ease;
  img {
    margin-right: 10px;
  }
  &:hover {
    background-color: #FFF1;
  }
  &:active {
    background-color: #EE6D0D33;
  }
  &:disabled {
    background-color: #837c7cDD;
    color: #484545;
    opacity: .8;
    border-color: #333;
  }
}

.dr-btn {
  background: transparent;
  border: 1px solid #C8435D;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 0.75rem 0 .10em;
  color: #f5f5f5;
  position: relative;
  margin: .5em;
  cursor: pointer;
  text-decoration: none;
  transition: all .15s ease;
  &:hover {
    background-color: #FFF1;
  }
  &:active {
    background-color: #C8435D33;
  }
}

.filter-btn {
  border: 0;
  padding: 1em;
  font-size: 1.02em;
  background-color: transparent;
  color: #F1F1F199;
  width: 100%;
  text-align: left;
  &:hover {
    cursor: pointer;
    background-color: #0002;
  }
  &:active {
    background-color: #1112;
  }
}

.app-btn {
  background: rgba(183,140,37,0.8);
  padding: .75rem;
  color: #f5f5f5;
  position: relative;
  margin: .5em;
  border: 0;
  cursor: pointer;
  text-decoration: none;

  &:disabled {
    filter: grayscale(80%);
    cursor: not-allowed;
  }

  &:not(:disabled) {
    &:active {
      background: rgba(183, 140, 37, 0.6);
    }

    &:after {
      content: '';
      border: 1px solid #ffce1f;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      transition: all .25s ease;
    }

    &:hover:after {
      border-color: #F5F5F5;
      top: -.5em;
      left: -.5em;
      right: -.5em;
      bottom: -.5em;
    }

    &:focus {
      outline: 0;
    }
  }
}

.social-button {
  color: #FFF;
  padding: .5em 1em;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  user-select: none;
  margin: .25em;
  white-space: nowrap;
  text-decoration: none;

  fa-icon {
    margin-right: .5em;
  }


  &.discord {
    background-color: #7289DA;
    &:hover {
      background-color: #758ddd;
    }
    &:active {
      background-color: #546bba;
    }
  }

  &.twitter {
    background-color: #1DA1F2;
    &:hover {
      background-color: #2ba9ff;
    }
    &:active {
      background-color: #1598e5;
    }

  }
}
