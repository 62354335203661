$assetPath : "/assets";

:root {
  --dr-pink: #C8435D;
  --dr-pink-dk: #d22643;
  --dr-blue: #0cf;
  --dr-blue-rgb: 0, 204, 255;
  --dr-blue-dk: #008EAF;
  --dr-blue-dk-rgb: 0, 142, 175;

  --box-primary: #212d2def;// #242934EF;
  --box-primary-dk: #212d2d;
  --box-secondary: rgba(23, 38, 38, 0.79); //#1d222dde;
  --box-primary-border: #212d2d;
  --box-secondary-border: #172626;
  --box-secondary-dk: #101c1c;
  --box-secondary-dker: #0e1818;

  --modale-body: #172626;

  --bungie-gold:rgba(183,140,37,0.8);
  --bungie-gold-rgb: 183,140,37;
  --bungie-gold-accent: #b78c25;
  --bungie-gold-dark: #ffce1f33;
  --bungie-gold-light: #ffce1f;

  --primary: #265B6A;
  --primary-rgb: 39, 91, 106;
  --success: #2A7E44;
  --danger: #AA3939;

  --pve: #4775c1;
  --pvp: #AA0000;
  --pve-lt: #70a0f1;
  --pvp-lt: #ff5c5c;
  --poly: #8a1ec1;

  --pve-rgb: 71, 117, 193;
  --pvp-rgb: 170, 0, 0;
  --poly-rgb: 138, 30, 193;

  --pve-hsl: 217, 50%, 52%;
  --pvp-hsl: 0, 100%, 33%;

  --void: #B185DF;
  --arc: #78BBE9;
  --solar: #F0631E;
  --stasis: #4d88ff;

  --dim-orange: #EE6D0D;
  --dim-orange-rgb: 238,109,13;


  --dark: 0, 0, 0;
  --exotic: 48, 61%, 50%;
  --legendary: 279, 36%, 29%;
  --rare: 213, 34%, 48%;
  --uncommon: 126, 35%, 32%;
  --common: 0, 0%, 80%;

  --exotic-item: #cdae32;
  --legendary-item: #a34be5;
  --rare-item: #427de8;
  --uncommon-item: #264e2e;

  --exoticDark: 48, 71%, 5%;
  --legendaryDark: 279, 36%, 5%;
  --rareDark: 213, 34%, 6%;
  --uncommonDark: 126, 35%, 5%;
  --commonDark: 0, 0%, 7%;

  --green: #40795D;
  --green-accent: #4d956a;
  --blue: #13333b;
  --red: #481b24;
  --purple: #352c56;

  --item-size: 64px;
  --socket-size: 35px;

  --perk-bg: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30'%3E%3Cpath fill='%23fff' opacity='.03' d='M21.747 19.5H30V21h-8.253zm-2.253 2.246h1.5V30h-1.5z'/%3E%3Cpath fill='%23fff' opacity='.15' d='M21.001 21v-1.5h-1.507V21h1.507z'/%3E%3Cpath fill='%23fff' opacity='.03' d='M19.494 0h1.5v3.752h-1.5zm2.253 4.498H30v1.5h-8.253zm-2.253 2.246h1.5v12.01h-1.5zM6.748 4.5H18.75V6H6.748zM4.494 0h1.5v3.754h-1.5zM0 4.5h3.749V6H0z'/%3E%3Cpath fill='%23fff' opacity='.15' d='M5.994 4.5h-1.5V6h1.508V4.5h-.008zm14.996 0h-1.5V6h1.508V4.5h-.008z'/%3E%3Cpath fill='%23fff' opacity='.03' d='M6.738 19.5h12.01V21H6.738zM4.494 6.746h1.5v12.009h-1.5zM0 19.5h3.749V21H0z'/%3E%3Cpath fill='%23fff' opacity='.15' d='M4.494 19.5h1.499V21H4.494z'/%3E%3Cpath fill='%23fff' opacity='.03' d='M4.494 21.746h1.5V30h-1.5z'/%3E%3C/svg%3E")
}
