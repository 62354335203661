@import "mixins";

.tab-container {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: stretch;
  overflow-x: hidden;
  height: 100%;

  .tab {
    flex: 1;
    .tab-header {
      text-align: center;
      padding: 1em;
      font-size: 1.1em;
      color: #FFF8;
      transition: all .25s ease;
      background-color: var(--box-secondary-dk);
      cursor: pointer;
      user-select: none;

      &:hover {
        color: #FFF;
        background-color: var(--box-secondary);
      }
      &:active {
        background-color: var(--box-primary);
      }
    }

    &.active .tab-header {
      color: #FFF;
      background-color: var(--box-secondary);
      border-bottom: 4px solid #FFF8;
    }

    .tab-content {
      position: absolute;
      pointer-events: none;
      opacity: 0;
      top: 60px;
      left: 0;
      right: 0;
      bottom: 0;
      transition: all .2s ease;
      overflow-y: auto;
      @include scrollbars(12px, var(--box-secondary-dker), var(--box-primary-border));
    }

    &.active .tab-content {
      opacity: 1;
      pointer-events: all;
    }
  }
}
