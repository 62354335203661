/* You can add global styles to this file, and also import other style files */
@import "mobile-drag-drop/default.css";
@font-face {
  font-family: 'Destiny Symbols';
  src: url(/assets/fonts/Destiny_Keys.otf) format('opentype');
  font-weight: 400;
  font-style: normal;
}

.menu {
  visibility: hidden;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
}

.destiny-symbol-text {
  font-family: "Destiny Symbols", sans-serif;
}

:root {

  --dr-pink: #C8435D;
  --dr-pink-dk: #d22643;
  --dr-blue: #0cf;
  --dr-blue-rgb: 0, 204, 255;
  --dr-blue-dk: #008EAF;
  --dr-blue-dk-rgb: 0, 142, 175;

  --box-primary: #273856EF; // #242934EF;
  --box-primary-dk: #151f31;

  --box-secondary: #212d3cde; //#1d222dde;

  --box-primary-opaque: #273856; // #242934EF;

  --box-primary-rgb: 174, 67, 43;
  --box-primary-lt: rgba(52, 90, 101, 0.94);
  --box-primary-border: #49808f;
  --box-secondary-border: #131015;
  --box-secondary-dk: #1b1921;
  --box-secondary-dker: #151218;

  --modale-body: rgb(47, 49, 54);

  --bungie-gold:rgba(183,140,37,0.8);
  --bungie-gold-light-rgb: 255, 206, 31;
  --bungie-gold-rgb: 183,140,37;
  --bungie-gold-accent: #b78c25;
  --bungie-gold-dark: #ffce1f33;
  --bungie-gold-light: #ffce1f;

  --primary: #265B6A;
  --primary-rgb: 39, 91, 106;
  --success: #2A7E44;
  --success-rgb: 42, 126, 68;
  --danger: #AA3939;

  --pve: #4775c1;
  --pvp: #AA0000;
  --poly: #8a1ec1;

  --void: #B185DF;
  --arc: #78BBE9;
  --solar: #F0631E;
  --stasis: #4d88ff;

  --dim-orange: #EE6D0D;
  --dim-orange-rgb: 238,109,13;


  --dark: 0, 0, 0;
  --exotic: 48, 61%, 50%;
  --legendary: 279, 36%, 29%;
  --rare: 213, 34%, 48%;
  --uncommon: 126, 35%, 32%;
  --common: 0, 0%, 80%;

  --exotic-item: #cdae32;
  --legendary-item: #a34be5;
  --rare-item: #427de8;
  --uncommon-item: #264e2e;

  --exoticDark: 48, 71%, 5%;
  --legendaryDark: 279, 36%, 5%;
  --rareDark: 213, 34%, 6%;
  --uncommonDark: 126, 35%, 5%;
  --commonDark: 0, 0%, 7%;

  --green: #40795D;
  --green-accent: #4d956a;
  --blue: #13333b;
  --red: #481b24;
  --purple: #352c56;

  --item-size: 64px;
  --socket-size: 35px;
}

body {
  color: #F1F1F1;
  font-family: 'Lato', sans-serif;
  padding: 0;
  margin: 0;
  background: #1F1F1F url('/assets/bg.webp') no-repeat fixed top;
  background-size: cover;
  height: 100vh;
  max-width: 100vw;
}

.ad-sticky {
  position: sticky;
  top: 25px;
}

.backdrop {
  background: rgba(0, 0, 0, .8);
  background: radial-gradient(circle, rgba(0,0,0,.2) 0%, rgba(0,0,0,1) 100%);
  position: fixed;
  top: 0;
  cursor: pointer;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
}

.light-level {
  color: #ffce1f;
  font-size: 1em;
  margin-left: 1.25em;
  display: inline-flex;
  align-items: center;

  svg {
    width: .8em;
    margin-right: 3px;
  }
}

.app-btn {
  background: rgba(183,140,37,0.8);
  padding: .75rem;
  color: #f5f5f5;
  position: relative;
  margin: .5em;
  border: 0;
  cursor: pointer;
  text-decoration: none;

  &.with-icon {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;
    gap: .5em;
  }

  &:disabled {
    filter: grayscale(80%);
    cursor: not-allowed;
  }

  &:not(:disabled) {
    &:active {
      background: rgba(183, 140, 37, 0.6);
    }

    &:after {
      content: '';
      border: 1px solid #ffce1f;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      transition: all .25s ease;
    }

    &:hover:after {
      border-color: #F5F5F5;
      top: -.5em;
      left: -.5em;
      right: -.5em;
      bottom: -.5em;
    }

    &:focus {
      outline: 0;
    }
  }
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1em 1em 5em 1em;
  box-sizing: border-box;

  small {
    font-weight: 300;
  }

  h1 {
    text-transform: uppercase;
    letter-spacing: 5px;
    margin-bottom: 5px;
    margin-top: 1em;
  }

  p.subtitle {
    margin-top: 0;
    font-weight: lighter;
  }

  app-item-list {
    margin-bottom: 1.5em;
  }

  .power-cap-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;

    .power-cap {
      padding: 1em;
      border: 1px solid #DDD;
      margin: .5em;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      color: #ffce1f;
      font-weight: bold;

      .light-icon {
        display: inline-block;
        height: .5em;
        width: .5em;
      }
    }
  }

  .credit {
    a {
      color: #FFF;
    }
  }
}

.footer {
  background: var(--box-secondary-dker);
  padding: 1em 0 110px;

  .logos {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1em;
    img {
      height: 50px;
    }
  }
}

.credit {
  display: block;
  text-align: center;
  margin-top: 1em;

  &.smer {
    opacity: .6;
    font-size: .7em;
  }

  img {
    border: none;
    height: 36px;
  }
  a {
    color: #FFF;
    font-weight: bold;
    &:hover {
      color: var(--dr-pink);
    }
  }

  &.links {
    display: flex;
    gap: .5em;
    align-items: center;
    justify-content: center;

    button {
      background: transparent;
      border: 0;
      opacity: .7;
      color: #FFF;
      cursor: pointer;
      font-weight: bold;
      padding: 0;
      &:hover {
        color: var(--dr-pink);
      }
    }
  }
}
.list-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2em;
  background-color: var(--box-secondary);
  box-shadow: 0 0 15px 1px #0006;
  padding: 0 1em 1em 1em;

  app-video-ad {
    max-width: 100%;
  }

  .list-header {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    padding: 1em;
    width: 100%;
    background-color: var(--box-primary);
    h4 {
      margin: 0;
      font-weight: bold;
      text-align: center;
      text-transform: uppercase;
      letter-spacing: 1px;
    }
  }

  .item-list {
    padding: 1em;
    display: grid;
    grid-gap: 10px;
    gap: 10px;
    align-content: start;
  }
}

.filters {
  padding: 0;
  margin-top: 2em;
  display: grid;
  grid-gap: 1em;
  gap: 1em;
  align-content: center;
}

.filter-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  app-dropdown {
    width: 100%;
  }

  .filter-name {
    margin-bottom: .5em;
    font-weight: 300;
    letter-spacing: 2px;
    text-transform: uppercase;
  }
}

.paginator ::ng-deep .ngx-pagination {
  a {
    color: rgb(242, 193, 97);

    &:hover {
      color: #F1F1F1;
      background: transparent;
    }
    &:focus {
      outline: 0;
    }
  }

  .current, .disabled {
    background-color: transparent;
    color: rgb(95, 106, 119);
    cursor: default;
  }

}

.has-action {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.clickable {
  opacity: .6;
  color: #FFF;
  cursor: pointer;
  text-decoration: none;
  text-transform: inherit;
  &:hover {
    opacity: 1;
  }
  &:active {
    opacity: .8;
  }
}

@media screen and (min-width: 1001px) {
  .item-list, .filters {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media screen and (min-width:651px) and (max-width: 1000px) {
  .item-list, .filters {
    grid-template-columns: 1fr 1fr;
  }
  .list-container {
    padding: 0;
  }
}

@media screen and (max-width: 650px) {
  .item-list, .filters {
    grid-template-columns: 1fr;
  }
  .list-header {
    box-sizing: border-box;
  }
  .list-container {
    padding: 5px;
  }
}


.flex-spacer {
  flex: 1;
}


@media screen and (min-width: 900px) {
  .hide-lg {
    display: none;
  }
  .hide-sm {
    display: inherit;
  }
}

@media screen and (max-width: 900px) {
  .hide-lg {
    display: inherit;
  }
  .hide-sm {
    display: none;
  }
}


.social-button {
  color: #FFF;
  padding: .5em 1em;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  user-select: none;
  margin: .25em;
  white-space: nowrap;
  text-decoration: none;

  fa-icon {
    margin-right: .5em;
  }


  &.discord {
    background-color: #7289DA;
    &:hover {
      background-color: #758ddd;
    }
    &:active {
      background-color: #546bba;
    }
  }

  &.twitter {
    background-color: #1DA1F2;
    &:hover {
      background-color: #2ba9ff;
    }
    &:active {
      background-color: #1598e5;
    }

  }
}

.objective {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 20px;
  margin-bottom: .5em;

  .check-objective {
    flex-basis: 20px;
    border: 1px solid #fff9;
    background-color: #000a;
    margin-right: 10px;
    height: 100%;
    box-sizing: border-box;
    position: relative;
    &:before {
      content: '';
      top: 2px;
      bottom: 2px;
      right: 2px;
      left: 2px;
      position: absolute;
    }
  }
  .progress-objective {
    flex-grow: 1;
    background-color: #000a;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    overflow: hidden;
    font-size: .8em;
    padding: 0 .5em;

    app-objective-description, .value {
      z-index: 1;
    }

    .description {
      font-family: 'Destiny Symbols', sans-serif;
      font-weight: 400;
      white-space: nowrap;
      word-break: break-all;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .progress {
      position: absolute;
      background-color: #5aa366;
      top: 0;
      left: 0;
      bottom: 0;
      z-index: 0;
    }
  }

  &.weapon-level-objective {
    .progress-objective {
      span {
        z-index: 1;
      }
      .progress {
        background-color: #D35137;
      }
    }
  }

  &.complete {
    opacity: .7;
    .progress-objective {
      .progress {
        background: transparent;
      }
    }

    .check-objective:before {
      background-color: #5aa366;
    }
  }
}

.clear-btn {
  background-color: transparent;
  border: 0;
  padding: .5em 1em;
  color: #FFFc;
  cursor: pointer;

  &:hover {
    background-color: #FFF5;
  }
  &:active {
    background-color: #FFF3;
  }
}

.warning-popup {
  border-top: 3px solid #CCC!important;
}


.dim-btn {
  background: transparent;
  border: 1px solid var(--dim-orange);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: .75rem;
  color: #f5f5f5;
  position: relative;
  margin: .5em;
  cursor: pointer;
  text-decoration: none;
  transition: all .15s ease;
  img {
    margin-right: 10px;
  }
  &:hover {
    background-color: #FFF1;
  }
  &:active {
    background-color: #EE6D0D33;
  }
  &:disabled {
    background-color: #837c7cDD;
    color: #484545;
    opacity: .8;
    border-color: #333;
  }
}

